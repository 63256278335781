<template>
  <v-container>
  <div class="d-flex mb-2">
    <div class="text-h5 pt-1">Sportler</div>
    <v-spacer></v-spacer>
    <router-link class="routerlink black--text" to="/adduser"><v-btn v-if="isTrainer" small color="primary" fab elevation="0"><v-icon>mdi-plus</v-icon></v-btn></router-link>
  </div>
  <v-overlay v-if="loading" :value="loading"><v-progress-circular :size="70" :width="7" color="white" indeterminate></v-progress-circular></v-overlay>
   <v-list v-else subheader two-line>
     <template v-for="(user,index) in userlist">
      <v-list-item class="px-0" :key="'U'+user.ID" @click="openUser(user.ID)">
        <v-list-item-avatar size="64" class="my-1">
          <v-img v-if="user.bild" :src="user.bild"></v-img>
          <v-avatar size="64" v-else color="deep-purple accent-1">
            <span class="white--text text-h5">{{ user.name.substr(0,1)+user.vorname.substr(0,1) }}</span>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="user.name+' '+user.vorname"></v-list-item-title>
          <v-list-item-subtitle v-html="user.email"></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-divider v-if="index < userlist.length - 1" :key="index"></v-divider>
     </template>
   </v-list>
  </v-container>
</template>

<script>

import axios from 'axios';

  export default {
    name: 'userlist',

    data: () => ({
      userlist:[],
      loading: false,
    }),
    computed:{
      isTrainer(){
        if (window && window.myuser) return window.myuser.isTrainer
        return false;
      }
    },
    methods:{
      async loadUser(force){
        this.loading = true
        let m = localStorage.getItem('user')
        if (m) this.userlist = JSON.parse(m)
        if (force || this.userlist.length<=0){
          await axios.get(process.env.VUE_APP_APIURL+"user",{
            headers: {
              'token': localStorage.getItem('token')
            }
          })
          .then(result => {
              if (result.status=="200"){
                  this.userlist = result.data.result;
                  localStorage.setItem('user',JSON.stringify(this.userlist))
              }
          }).catch(() => {
            //this.$router.push('/login')
          });
        }
        this.loading = false
      },
      openUser(id){
        this.$router.push('/user/'+id)
      }
    },
    mounted(){
      this.loadUser()
    },
  }
</script>
