<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <!--<div class="d-flex align-center">
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
      </div>-->
      <v-toolbar-title>{{ title }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu offset-y v-if="isTrainer">
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
        </template>
        <v-list>
          <router-link class="routerlink black--text" to="/home"><v-list-item><v-list-item-title>Sportler</v-list-item-title></v-list-item></router-link>
          <!--<router-link class="routerlink black--text" to="/adduser"><v-list-item><v-list-item-title>Sportler anlegen</v-list-item-title></v-list-item></router-link>-->
          <router-link class="routerlink black--text" to="/uebungen"><v-list-item><v-list-item-title>Übungen</v-list-item-title></v-list-item></router-link>
          <router-link class="routerlink black--text" to="/sportdata_turnier_einlesen"><v-list-item><v-list-item-title>Sportdata Turnier einlesen</v-list-item-title></v-list-item></router-link>
          <v-list-item @click="update"><v-list-item-title>Daten aktualisieren</v-list-item-title></v-list-item>
        </v-list>
      </v-menu>
      <router-link class="routerlink" v-else-if="inited && $route.path.substr(0,9)=='/edititem'" :to="'/user/'+$route.params.userid"><v-btn text fab><v-icon>mdi-chevron-left</v-icon></v-btn></router-link>
      <router-link class="routerlink" v-else-if="inited && $route.path.substr(0,9)=='/showitem'" :to="'/user/'+$route.params.userid"><v-btn text fab><v-icon>mdi-chevron-left</v-icon></v-btn></router-link>
      <router-link class="routerlink" v-else-if="inited && $route.path.substr(0,9)=='/edituser'" :to="'/user/'+$route.params.id"><v-btn text fab><v-icon>mdi-chevron-left</v-icon></v-btn></router-link>
      <router-link class="routerlink" v-else-if="inited && $route.path.substr(0,7)=='/meine_'" :to="'/user/'+$route.params.id"><v-btn text fab><v-icon>mdi-chevron-left</v-icon></v-btn></router-link>
    </v-app-bar>
    <v-main class="pb-11">
      <router-view></router-view>
    </v-main>
    <v-bottom-navigation fixed>
        <v-btn v-if="isTrainer" to="/home"><span>Sportler</span><v-icon>mdi-account-group-outline</v-icon></v-btn>
        <v-btn v-if="isTrainer" to="/uebungen"><span>Übungen</span><v-icon>mdi-weight-lifter</v-icon></v-btn>
        <!--<v-btn v-if="isTrainer" to="/sportdata_turnier_einlesen"><span>Turnier</span><v-icon>mdi-clipboard-text-outline</v-icon></v-btn>-->
        <v-btn v-if="!isTrainer" :to="'/meine_baustellen/'+$route.params.id"><span>Meine Baustellen</span><v-icon>mdi-excavator</v-icon></v-btn>
        <v-btn v-if="!isTrainer" :to="'/meine_uebungen/'+$route.params.id"><span>Meine Übungen</span><v-icon>mdi-weight-lifter</v-icon></v-btn>
        <v-btn v-if="!isTrainer" :to="'/meine_wertungen/'+$route.params.id"><span>Meine Wertungen</span><v-icon>mdi-clipboard-text-outline</v-icon></v-btn>
      <!--<div class="text-caption">Version {{ appversion }}</div>-->
    </v-bottom-navigation>
  </v-app>
</template>

<script>

import axios from 'axios';
import {version} from './../package'
export default {
  name: 'App',
  data: () => ({
    DEFAULT_TITLE : 'CoachMe',
    title: null,
    inited: false,
    bottomNav: true,
    appversion: version
  }),
  methods:{
    update: function(){
      localStorage.removeItem("user")
      localStorage.removeItem("uebungen")
      if (this.isTrainer){
        this.$router.push('/home')
      }else{
        this.$router.push('/user/'+window.myuser.ID)
      }
    },
    async init(){
      await axios.get(process.env.VUE_APP_APIURL+"init",{
        headers: {
          'token': localStorage.getItem('token')
        }
      })
      .then(result => {
          if (result.status=="200"){
            window.myuser = result.data.result.user;
            window.arten = result.data.result.arten
            window.muskelgruppen= result.data.result.muskelgruppen
            this.inited = true;
            if (window.myuser){
              if (this.$route.name!="Sportler"){
                if (this.isTrainer && this.$route.path !== '/home'){
                  this.$router.push('/home')
                }else if(this.$route.path !== '/user/'+window.myuser.ID){
                  this.$router.push('/user/'+window.myuser.ID)
                }
              }
            }else if(this.$route.path !== '/login'){
              this.$router.push('/login')
            }
          }
      }).catch(() => {
        this.$router.push('/login')
      });
    },
  },
  mounted(){
    this.init()
  },
  computed:{
    isTrainer(){
      if (this.inited && window.myuser) return window.myuser.isTrainer;
      return false;
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
          document.title = this.DEFAULT_TITLE+" - "+to.name|| this.DEFAULT_TITLE;
          this.title = document.title;
      }
    },
  }
};
</script>

<style>
.routerlink{
  text-decoration: none !important;
}
</style>