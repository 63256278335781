export default {
    methods:{
        mysqlToLocalDate (date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },
        copyStrToClipboard(string){
            var el=document.createElement('input');
            el.setAttribute('style','position:absolute;top:-9999px');
            el.value=string;
            document.body.appendChild(el);
            var range = document.createRange();
            el.contentEditable=true;
            el.readOnly = false;
            range.selectNodeContents(el);
            var s=window.getSelection();
            s.removeAllRanges();
            s.addRange(range);
            el.setSelectionRange(0, 999999);
            document.execCommand('copy');
            el.remove();
        },
        filterArray(items,suchstring,arrayProps){
            var r = []
            let sFound = false
            if (!suchstring) suchstring=""
            var ss = suchstring.toLowerCase().split(" ");
            for (var item of items){
                if (suchstring){
                    for(var s of ss){
                        if (s.trim()=="") continue;
                        sFound = false
                        for(var prop of arrayProps){
                            if (item[prop] && item[prop].toLowerCase().indexOf(s)>=0){
                                sFound = true
                                break
                            }
                        }
                        if (!sFound) break
                    }
                    if(sFound) r.push(item)
                }else{
                    r.push(item)
                }
            }
            return r;
        }
    }
};