<template>
  <v-container>
    <v-snackbar centered color="error" :value="this.error" timeout="5000">{{ this.error }}</v-snackbar>
    <v-form>
      <div class="mb-8 d-flex justify-space-between">
        <v-btn color="grey" dark style="width:45%" @click="back">Abbrechen</v-btn>
        <v-btn color="primary"  style="width:45%" :loading="uploading" @click="save">Anlegen</v-btn>
      </div>
      <v-file-input v-model="bild" label="Bild/Video" accept="image/*,video/*" truncate-length="50" :prepend-icon="null" prepend-inner-icon="mdi-camera" outlined persistent-placeholder></v-file-input>
      
      <v-text-field maxlength="50" v-model="name" :rules="nameRules" label="Name" required clearable outlined persistent-placeholder></v-text-field>
      <v-combobox maxlength="20" v-model="art" :items="arten" label="Art" outlined persistent-placeholder></v-combobox>
      <v-combobox maxlength="20" v-model="muskelgruppe" :items="muskelgruppen" label="Muskelgruppe" outlined persistent-placeholder></v-combobox>

      <v-textarea v-model="beschreibung" label="Beschreibung" clearable outlined persistent-placeholder></v-textarea>

      <v-row >
        <v-col cols="6"><v-text-field maxlength="10" hide-details v-model="saetze" label="Sätze" clearable outlined persistent-placeholder></v-text-field></v-col>
        <v-col cols="6"><v-text-field maxlength="10" hide-details v-model="wiederholungen" label="Wiederholungen" clearable outlined persistent-placeholder></v-text-field></v-col>
        <v-col cols="6"><v-text-field maxlength="10" hide-details v-model="zeit_sek" label="Zeit in Sek." clearable outlined persistent-placeholder></v-text-field></v-col>
        <v-col cols="6"><v-text-field maxlength="10" hide-details v-model="alle_x_tage" label="Alle ... Tage" clearable outlined persistent-placeholder></v-text-field></v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>

import axios from 'axios';

  export default {
    name: 'adduebung',
    data: () => ({
      name: "",
      beschreibung: "",
      art: "",
      muskelgruppe: "",
      saetze: "",
      wiederholungen: "",
      zeit_sek: "",
      alle_x_tage: "",
      bild: null,
      uploading: false,
      error: false,
      nameRules: [
        v => !!v || 'Angabe erforderlich',
      ],
    }),
    computed:{
      arten(){
        return window.arten
      },
      muskelgruppen(){
        return window.muskelgruppen
      }
    },
    methods:{
      async save() {
        this.uploading = true;
        let formData = new FormData();
        formData.append("bild", this.bild);
        formData.append("name", this.name);
        formData.append("beschreibung", this.beschreibung);
        formData.append("saetze", this.saetze);
        formData.append("muskelgruppe", this.muskelgruppe);
        formData.append("art", this.art);
        formData.append("wiederholungen", this.wiederholungen);

        formData.append("zeit_sek", this.zeit_sek);
        formData.append("alle_x_tage", this.alle_x_tage);
        
        await axios.post(process.env.VUE_APP_APIURL+"uebung/",formData,{
          headers: {
            'token': localStorage.getItem('token'),
            "Content-Type": "multipart/form-data"
          }
        }).then((r) => {
          this.uploading = false;
          if (r.data.result){  
            this.$router.push('/uebungen')
          }else{
              this.error = r.data.error
          }

        }).catch((error) => {
          this.error = error.message
        })
      },
      back(){
        this.$router.push('/uebungen')
      }
    },
    mounted(){

    },
  }
</script>
