<template>
  <v-container>

    <v-snackbar centered color="error" :value="this.error" timeout="5000">{{ this.error }}</v-snackbar>
    <v-form>
      <div class="mb-8 d-flex justify-space-between">
        <v-btn style="width:45%" color="grey" dark @click="back">Abbrechen</v-btn>
        <v-btn style="width:45%" color="primary" :loading="uploading" @click="save">Speichern</v-btn>
      </div>
      <v-text-field maxlength="50" outlined persistent-placeholder v-model="name" :rules="nameRules" label="Name" required clearable></v-text-field>
      <v-combobox maxlength="20" outlined persistent-placeholder v-model="art" :items="arten" label="Art"></v-combobox>
      <v-combobox maxlength="20" outlined persistent-placeholder v-model="muskelgruppe" :items="muskelgruppen" label="Muskelgruppe"></v-combobox>
      <v-row class="mb-4">
        <v-col cols="6"><v-text-field maxlength="10" outlined persistent-placeholder hide-details v-model="saetze" label="Sätze" clearable></v-text-field></v-col>
        <v-col cols="6"><v-text-field maxlength="10" outlined persistent-placeholder hide-details v-model="wiederholungen" label="Wiederholungen" clearable></v-text-field></v-col>
        <v-col cols="6"><v-text-field maxlength="10" outlined persistent-placeholder hide-details v-model="zeit_sek" label="Zeit in Sek." clearable></v-text-field></v-col>
        <v-col cols="6"><v-text-field maxlength="10" outlined persistent-placeholder hide-details v-model="alle_x_tage" label="Alle ... Tage" clearable></v-text-field></v-col>
      </v-row>
      <v-textarea outlined persistent-placeholder hide-details class="mb-4" v-model="beschreibung" label="Beschreibung" clearable></v-textarea>   
    
      <v-img class="mb-4" v-if="anzeigebild" :src="anzeigebild"></v-img>
      <v-file-input v-if="anzeigebild && anzeigebild!='deleted' && !bild" v-model="bild" label="Bild / Video" accept="image/*,video/*" outlined persistent-placeholder truncate-length="50" :prepend-icon="null" prepend-inner-icon="mdi-camera" append-icon="mdi-close" @click:append="delFile"></v-file-input>
      <v-file-input v-else v-model="bild" label="Bild / Video" accept="image/*,video/*" truncate-length="50" :prepend-icon="null" outlined persistent-placeholder prepend-inner-icon="mdi-camera"></v-file-input>
      <v-btn color="error" class="mt-10" block @click="del">Löschen</v-btn>
    </v-form>
  </v-container>
</template>

<script>

import axios from 'axios';
  export default {
    name: 'edituebung',
    data: () => ({
      name: "",
      beschreibung: "",
      saetze: "",
      wiederholungen: "",
      muskelgruppe: "",
      art: "",
      zeit_sek: "",
      alle_x_tage: "",
      anzeigebild: null,
      bild: null,
      nameRules: [
        v => !!v || 'Angabe erforderlich',
      ],
      uploading:false,
      t: "",
      error: null,
    }),
    props:["id"],
    methods:{
      back(){
        this.$router.push('/uebungen')
      },
      async del(){
        await axios.delete(process.env.VUE_APP_APIURL+"uebung/"+this.id,{
          headers: {
            'token': localStorage.getItem('token')
          }
        }).then(() => {
            this.back()
        });
      },
      delFile(){
        this.anzeigebild = "deleted"
        this.bild = null;
      },
      async save(){
        await setTimeout(() => {
          this.uploading = true;
          let formData = new FormData();
          formData.append("bild", this.bild);
          formData.append("anzeigebild", this.anzeigebild);
          formData.append("name", this.name);
          formData.append("beschreibung", this.beschreibung);
          formData.append("muskelgruppe", this.muskelgruppe);
          formData.append("art", this.art);
          formData.append("saetze", this.saetze);
          formData.append("wiederholungen", this.wiederholungen);
          formData.append("zeit_sek", this.zeit_sek);
          formData.append("alle_x_tage", this.alle_x_tage);

          axios.post(process.env.VUE_APP_APIURL+"uebung/"+this.id,formData,{
            headers: {
              'token': localStorage.getItem('token'),
              "Content-Type": "multipart/form-data"
            }
          }).then((r) => {
            console.log(r);
            this.uploading = false;
            if (r.data.result){  
              this.back()
            }else{
              this.error = r.data.error
            }
          }).catch((error) => {
            this.error = error.message
          })
        },50)
        
      },
      async loadItem(){
        await axios.get(process.env.VUE_APP_APIURL+"uebung/"+this.id,{
          headers: {
            'token': localStorage.getItem('token')
          }
        }).then(result => {
            this.name = result.data.result.name;
            this.beschreibung = result.data.result.beschreibung
            this.muskelgruppe = result.data.result.muskelgruppe
            this.art = result.data.result.art
            this.saetze = result.data.result.saetze
            this.wiederholungen = result.data.result.wiederholungen
            this.zeit_sek = result.data.result.zeit_sek
            this.alle_x_tage = result.data.result.alle_x_tage
            this.anzeigebild = result.data.result.bild
            this.bild = null
        });
      },
    },
    computed:{
      arten(){
        return window.arten
      },
      muskelgruppen(){
        return window.muskelgruppen
      }
    },
    mounted(){
      this.loadItem()
    },
  }
</script>
