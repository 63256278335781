<template>
  <v-container>
    <v-dialog :value="showUebungDlg" @click:outside="show=null" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card v-if="showUebungDlg"  v-touch="{ down: () => onSwipeDown(), up: () => onSwipeUp() }">
        <v-card-title class="primary white--text d-flex">
          <div>{{ show.name }}</div>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="show = null"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          <video playsinline v-if="current_video" :src="current_video" controls loop muted autoplay width="100%"></video>
          <img v-if="current_image" :src="current_image" width="100%" height="100%" />
          <div class="d-flex justify-space-between">
            <div v-if="show.saetze>0">Sätze: {{ show.saetze }}</div>
            <div v-if="show.wiederholungen>0">Wdh: {{ show.wiederholungen }}</div>
            <div v-if="show.zeit_sek>0">Dauer: {{ show.zeit_sek }} Sek.</div>
            <div v-if="show.alle_x_tage>0">alle {{ show.alle_x_tage }} Tage</div>
          </div>
          <div v-if="show.beschreibung" class="mt-2 text-body2">{{ show.beschreibung }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="d-flex mb-2">
      <div class="text-h5 pt-1">Übungen</div>
      <v-spacer></v-spacer>
      <v-btn v-if="isTrainer" small color="primary"  @click="unmark" fab elevation="0">
        <v-icon>mdi-checkbox-marked-circle-minus-outline</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="isTrainer" small color="primary"  @click="add" fab elevation="0">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-text-field label="Suchen" hide-details class="mb-3" v-model="suchen" prepend-icon="mdi-magnify" outlined clearable></v-text-field>
    <v-overlay v-if="loading" :value="loading"><v-progress-circular :size="70" :width="7" color="white" indeterminate></v-progress-circular></v-overlay>
   <v-list v-else subheader two-line>
     <template v-for="(uebung,index) in uebungslistFiltered">
      <v-list-item class="px-0" :key="'U'+uebung.ID" :class="isMarkiert(uebung) ? 'green lighten-4' : ''" @click="ansehen(uebung)" v-touch="{ left: () => markieren(uebung,true), right: () => markieren(uebung,false)}">
        <v-list-item-avatar size="64" class="my-1" rounded="0">
          <v-img v-if="uebung.bild" :src="uebung.bild"></v-img>
          <v-avatar size="64" v-else color="deep-purple accent-1">
            <span class="white--text text-h5">{{ uebung.name.substr(0,2) }}</span>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content class="py-1">
          <v-list-item-title v-html="uebung.name"></v-list-item-title>
          <div class="text-caption text-no-wrap text-truncate mb-0" v-html="uebung.art"></div>
          <div class="text-caption text-no-wrap text-truncate mb-0" v-html="uebung.muskelgruppe"></div>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="primary" @click="editUebung(uebung.ID)">mdi-pencil</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-divider v-if="index < uebungslistFiltered.length - 1" :key="index"></v-divider>
     </template>
   </v-list>
  </v-container>
</template>

<script>

import axios from 'axios';
import functions from '@/mixins/functions';

  export default {
    name: 'uebungen',
    mixins:[functions],
    data: () => ({
      uebungslist:[],
      show: null,
      loading: false,
      current_video: null,
      current_image: null,
      arten: [],
      muskelgruppen: [],
      markiert: [],
      suchen: "",
    }),
    methods:{
      unmark(){
        this.markiert = []
        localStorage.setItem('markiert',JSON.stringify(this.markiert))
      },
      onSwipeDown(){
        this.show = null
      },
      onSwipeUp(){
        this.markieren(this.show,true)
        this.show = null
      },
      onSwipe(uebung,$event){
        if ($event.type=='swiperight'){
          this.markieren(uebung,false)
        }
        if ($event.type=='swipeleft'){
          this.markieren(uebung,true)
        }
      },
      markieren(uebung,markieren){
        if (markieren){
          if (!this.isMarkiert(uebung)){
            this.markiert.push(uebung.ID);
            localStorage.setItem('markiert',JSON.stringify(this.markiert))
          }
        }else{
          let idx = this.markiert.indexOf(uebung.ID)
          if (idx>=0){
            this.markiert.splice(idx,1);
            localStorage.setItem('markiert',JSON.stringify(this.markiert))
          }
        }
      },
      isMarkiert(uebung){
        if (this.markiert.indexOf(uebung.ID)<0) return false
        return true
      },
      ansehen(item){
        var url = process.env.VUE_APP_APIURL+"mediaurl/"+item.ID+"/uebung/"+item.itemtoken
        if (item.mimetype.indexOf("video")>=0){
          this.current_video = url
          this.current_image = null
        }else if (item.mimetype.indexOf("image")>=0){
          this.current_video = null
          this.current_image = url
        }else{
          this.current_video = null
          this.current_image = null
        }
        this.show = item
      },
      async loadUebungen(force){
        this.loading = true
        let m = localStorage.getItem('uebungen')
        if (m) this.uebungslist = JSON.parse(m)

        if (force || this.uebungslist.length<=0){
          await axios.get(process.env.VUE_APP_APIURL+"uebungen",{
            headers: {
              'token': localStorage.getItem('token')
            }
          })
          .then(result => {
              if (result.status=="200"){
                  this.uebungslist = result.data.result;
                  localStorage.setItem('uebungen',JSON.stringify(this.uebungslist))
              }
          }).catch(() => {
            //this.$router.push('/login')
          });
        }
        this.loading = false
      },
      add(){
        this.$router.push('/adduebung')
      },
      editUebung(id){
        this.$router.push('/edituebung/'+id)
      },
    },
    mounted(){
      this.loadUebungen(false)
      let m = localStorage.getItem('markiert')
      if (m) this.markiert = JSON.parse(m)
    },
    computed:{
      uebungslistFiltered(){
        return this.filterArray(this.uebungslist,this.suchen,["art","name","muskelgruppe","beschreibung"])
      },
      showUebungDlg(){
        return this.show && this.show.name
      },
      isTrainer(){
        if (window && window.myuser) return window.myuser.isTrainer
        return false;
      }
    },
  }
</script>
<style scoped>

</style>