<template>
  <v-container>
    <v-dialog :value="uebungwaehlen" hide-overlay fullscreen>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Übungen</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="save_meine_uebungen"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-list subheader two-line>
            <template v-for="(uebung,index) in uebungslist">
              <v-list-item class="px-0" :key="'U'+uebung.ID">
                <v-list-item-avatar size="64" class="my-1">
                  <v-img v-if="uebung.bild" :src="uebung.bild"></v-img>
                  <v-avatar size="40" v-else color="deep-purple accent-1">
                    <span class="white--text text-h5">{{ uebung.name.substr(0,2) }}</span>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="uebung.name"></v-list-item-title>
                  <div class="text-caption text-no-wrap text-truncate mb-0" v-html="uebung.art"></div>
                  <div class="text-caption text-no-wrap text-truncate mb-0" v-html="uebung.muskelgruppe"></div>
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox v-model="meine_uebungen"  multiple :value="uebung.ID"></v-checkbox>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index < uebungslist.length - 1" :key="index"></v-divider>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog :value="showUebungDlg" @click:outside="show=null" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card v-if="showUebungDlg"  v-touch="{ down: () => onSwipeDown(), up: () => onSwipeUp() }">
        <v-card-title class="primary white--text d-flex">
          <div>{{ show.name }}</div>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="show = null"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          <video playsinline v-if="current_video" :src="current_video" controls loop muted autoplay width="100%"></video>
          <img v-if="current_image" :src="current_image" width="100%" height="100%" />
          <div class="d-flex justify-space-between">
            <div v-if="show.saetze">Sätze: {{ show.saetze }}</div>
            <div v-if="show.wiederholungen">Wdh: {{ show.wiederholungen }}</div>
            <div v-if="show.zeit_sek">Dauer: {{ show.zeit_sek }} Sek.</div>
            <div v-if="show.alle_x_tage">alle {{ show.alle_x_tage }} Tage</div>
          </div>
          <div v-if="show.beschreibung" class="mt-2 text-body2">{{ show.beschreibung }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="d-flex mb-2">
      <div class="text-h5 pt-1">Meine Übungen</div>
      <v-spacer></v-spacer>
      <v-btn  small color="primary"  @click="unmark" fab elevation="0">
        <v-icon>mdi-checkbox-marked-circle-minus-outline</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="isTrainer" small color="primary"  @click="add" fab elevation="0"><v-icon>mdi-weight-lifter</v-icon></v-btn>
    </div>
    <v-text-field label="Suchen" hide-details class="mb-3" v-model="suchen" prepend-icon="mdi-magnify" outlined clearable></v-text-field>
    <v-overlay v-if="loading" :value="loading"><v-progress-circular :size="70" :width="7" color="white" indeterminate></v-progress-circular></v-overlay>
    <v-list v-else-if="items.length>0" subheader two-line>
      <template v-for="(uebung,index) in myItems">
        <v-list-item class="px-0" :key="'U'+uebung.ID" :class="isMarkiert(uebung) ? 'green lighten-4' : ''" @click="ansehen(uebung)" v-touch="{ left: () => markieren(uebung,true), right: () => markieren(uebung,false)}">
          <v-list-item-avatar size="64" class="my-1" rounded="0">
            <v-img v-if="uebung.bild" :src="uebung.bild"></v-img>
            <v-avatar size="40" v-else color="deep-purple accent-1">
              <span class="white--text text-h5">{{ uebung.name.substr(0,2) }}</span>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content class="py-1">
            <v-list-item-title v-html="uebung.name"></v-list-item-title>
            <div class="d-flex text-caption justify-start">
              <div class="mr-3" v-if="uebung.saetze">Sätze: {{ uebung.saetze }}</div>
              <div class="mr-3" v-if="uebung.wiederholungen">Wdh: {{ uebung.wiederholungen }}</div>
              <div class="mr-3" v-if="uebung.zeit_sek">Dauer: {{ uebung.zeit_sek }} Sek.</div>
              <div v-if="uebung.alle_x_tage">alle {{ uebung.alle_x_tage }} Tage</div>
            </div>
          </v-list-item-content>
          <v-list-item-action v-if="isTrainer">
            <v-icon color="primary" @click="editUebung(uebung)">mdi-pencil</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="index < uebungslist.length - 1" :key="index"></v-divider>
      </template>
    </v-list>
    <div v-else>
      <v-card class="mt-5">
        <v-card-text class="text-center text-h5">Keine Übungen vorhanden</v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>

import axios from 'axios';
import functions from '@/mixins/functions';

  export default {
    name: 'meine_uebungen',
    mixins:[functions],
    data: () => ({
      items:[],
      uebungslist: [],
      meine_uebungen: [],
      loading: false,
      suchen: "",
      uebungwaehlen: false,
      current_video: false,
      current_video_mime_type: false,
      current_image: false,
      markiert: [],
      show: null,
    }),
    props:["id"],
    computed:{
      showUebungDlg(){
        return this.show && this.show.name
      },
      isTrainer(){
        if (window && window.myuser) return window.myuser.isTrainer
        return false;
      },
      myItems(){
        let i = []
        for(let item of this.items){
          item.wiederholungen = item.my_wiederholungen ?? item.wiederholungen
          item.saetze = item.my_saetze ?? item.saetze
          item.zeit_sek = item.my_zeit_sek ?? item.zeit_sek
          item.alle_x_tage = item.my_alle_x_tage ?? item.alle_x_tage
          i.push(item)
        }
        return this.filterArray(i,this.suchen,["art","name","muskelgruppe","beschreibung"])
      }
    },
    methods:{
      unmark(){
        this.markiert = []
        localStorage.setItem('markiert',JSON.stringify(this.markiert))
      },
      onSwipeDown(){
        this.show = null
      },
      onSwipeUp(){
        this.markieren(this.show,true)
        this.show = null
      },
      onSwipe(uebung,$event){
        if ($event.type=='swiperight'){
          this.markieren(uebung,false)
        }
        if ($event.type=='swipeleft'){
          this.markieren(uebung,true)
        }
      },
      markieren(uebung,markieren){
        console.log('markieren')
        if (markieren){
          if (!this.isMarkiert(uebung)){
            this.markiert.push(uebung.ID);
            localStorage.setItem('markiert',JSON.stringify(this.markiert))
          }
        }else{
          let idx = this.markiert.indexOf(uebung.ID)
          if (idx>=0){
            this.markiert.splice(idx,1);
            localStorage.setItem('markiert',JSON.stringify(this.markiert))
          }
        }
      },
      isMarkiert(uebung){
        if (this.markiert.indexOf(uebung.ID)<0) return false
        return true
      },
      async save_meine_uebungen(){
        await axios.post(process.env.VUE_APP_APIURL+"meine_uebungen/"+this.id,{"meine_uebungen": this.meine_uebungen},{
          headers: {
            'token': localStorage.getItem('token')
          }
        })
        .then(result => {
            if (result.status=="200"){
              this.$set(this,"items",result.data.result.items)
              this.uebungwaehlen = false
            }
        });
        
      },
      add(){
        this.loadUebungen()
        this.uebungwaehlen = true
      },
      async init() {
        this.loading = true
        await axios.get(process.env.VUE_APP_APIURL+"meine_uebungen/"+this.id,{
          headers: {
            'token': localStorage.getItem('token')
          }
        }).then((r) => {
          this.loading = false
          if(r.status=="200"){
            this.meine_uebungen = []
            for(let u of r.data.result.items){
              this.meine_uebungen.push(u.ID)
            }
            this.items = r.data.result.items
          }else{
            this.error = r.error
          }
        }).catch((error) => {
          this.loading = false
          this.error = error.message
        })
      },
      async loadUebungen(){

        await axios.get(process.env.VUE_APP_APIURL+"uebungen",{
          headers: {
            'token': localStorage.getItem('token')
          }
        })
        .then(result => {
            if (result.status=="200"){
                this.uebungslist = result.data.result;
            }
        })
      },
      ansehen(item){
        var url = process.env.VUE_APP_APIURL+"mediaurl/"+item.ID+"/uebung/"+item.itemtoken
        if (item.mimetype.indexOf("video")>=0){
          this.current_video = url
          this.current_image = null
        }else if (item.mimetype.indexOf("image")>=0){
          this.current_video = null
          this.current_image = url
        }else{
          this.current_video = null
          this.current_image = null
        }

        this.show = item
        this.show.wiederholungen = item.my_wiederholungen ?? item.wiederholungen
        this.show.saetze = item.my_saetze ?? item.saetze
        this.show.zeit_sek = item.my_zeit_sek ?? item.zeit_sek
        this.show.alle_x_tage = item.my_alle_x_tage ?? item.alle_x_tage
      },
      editUebung(uebung){
        console.log('editUebung',uebung)
      },
    },
    mounted(){
      this.init()
    },
  }
</script>
<style scoped>
div>>>.v-timeline-item__body{
  top:-8px !important;
}

.v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 48px;
}
</style>